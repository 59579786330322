// composables/useScrollToDiv.ts

interface Nav {
  id: number;
  name: string;
  path: string;
  divId: string;
}
export default function useScrollToDiv(navs: Nav[]) {
  const router = useRouter();

  const scrollToDiv = (divId: string) => {
    const element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onRouteChange = (to: any) => {
    const nav = navs.find((item) => item.path === to.path);
    if (nav) {
      setTimeout(() => scrollToDiv(nav.divId), 100); // Delay to ensure the element is rendered
    }
  };

  onMounted(() => {
    router.afterEach(onRouteChange);
  });

  onUnmounted(() => {
    router.afterEach(() => {});
  });
}
